import {
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Box,
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'
import SettingsIcon from '@material-ui/icons/Settings'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo'
import CachedIcon from '@material-ui/icons/Cached'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh'
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded'
import BugReportIcon from '@material-ui/icons/BugReport'
import DescriptionIcon from '@material-ui/icons/Description'
import { withRouter } from 'react-router'

import { ReactComponent as BravosLogo } from '../assets/BravosFull.svg'
import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 'none',
            backgroundColor: theme.palette.primary.main,
            boxShadow: '0 4px 18px 3px rgba(0,0,0,0.23)',
            justifyContent: 'space-between',
        },
        '& .MuiListItemIcon-root': {
            paddingLeft: theme.spacing(1),
            marginRight: theme.spacing(-3),
        },
        '& .MuiListItem-root': {
            '&:hover': {
                background: '#e9003c',
                '&> *': {
                    color: theme.palette.secondary.main,
                },
            },
        },
    },
    drawer: {
        width: 325,
        height: '100vh',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    paper: {
        background: theme.palette.primary.main,
    },
    content: {
        flexGrow: 1,
        backgroundColor: '#fff',
        // minHeight: "100vh",
        padding: theme.spacing(3),
    },
    clickableHeader: {
        display: 'flex',
        flexDirection: 'columns',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        marginLeft: theme.spacing(1),
    },
    list: {
        paddingBottom: '1.1rem',
        '& > * ': {
            color: '#eceff1',
        },
    },
    copyright: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}))

const Layout = (props) => {
    const { children, history } = props
    const classes = useStyles()
    const date = new Date()
    const year = date.getFullYear()
    const { logout } = useAuth0()

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        })
    }

    return (
        <div className={classes.root}>
            <Drawer variant="permanent" classes={{ root: classes.drawer }}>
                <div>
                    <Box
                        className={classes.clickableHeader}
                        onClick={() => history.push('/')}
                    >
                        <BravosLogo
                            style={{
                                height: '100px',
                                width: '200px',
                                marginRight: '1.2rem',
                                marginLeft: '1rem',
                            }}
                        />
                    </Box>
                    <List>
                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() => history.push('/mno_pipeline')}
                        >
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText primary="MNO Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/data_transformation_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <TrendingUpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Data Transformations Pipeline (D/W)" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/data_extraction_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Data Extraction Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/dam_post_processing_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="DAM Post Processing Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() => history.push('/auction_pipeline')}
                        >
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Auction Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() => history.push('/auction_ii_pipeline')}
                        >
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Auction II Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/auction_model_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Auction Model Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/master_model_data_prep_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <BrightnessHighIcon />
                            </ListItemIcon>
                            <ListItemText primary="Master Model Data Prep Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/mini_master_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <BrightnessHighIcon />
                            </ListItemIcon>
                            <ListItemText primary="Mini Master Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/portfolio_stats_pipeline')
                            }
                        >
                            <ListItemIcon>
                                <AssignmentTurnedInRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Portfolio Stats Pipeline" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list,
                            }}
                            onClick={() => history.push('/scraper_stats')}
                        >
                            <ListItemIcon>
                                <BugReportIcon />
                            </ListItemIcon>
                            <ListItemText primary="Scraper Stats" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list,
                            }}
                            onClick={() => history.push('/lp_stats')}
                        >
                            <ListItemIcon>
                                <BugReportIcon />
                            </ListItemIcon>
                            <ListItemText primary="LP Stats" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/global_configurations')
                            }
                        >
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Global Configurations (RSY)" />
                        </ListItem>

                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() => history.push('/run_configurations')}
                        >
                            <ListItemIcon>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText primary="Run Configurations (MNO)" />
                        </ListItem>
                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() => history.push('/run_modifications')}
                        >
                            <ListItemIcon>
                                <SettingsInputSvideoIcon />
                            </ListItemIcon>
                            <ListItemText primary="Run Modifications (TUX)" />
                        </ListItem>
                        <ListItem
                            button
                            classes={{
                                root: classes.list, // class name, e.g. `classes-nesting-root-x`
                            }}
                            onClick={() =>
                                history.push('/custom_auction_modifications')
                            }
                        >
                            <ListItemIcon>
                                <CachedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Custom Auction Modifications (CAM)" />
                        </ListItem>
                    </List>
                </div>
                <Box className={classes.copyright}>
                    <ListItem
                        button
                        classes={{
                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                        }}
                        className={classes.listItem}
                        style={{ marginBottom: '1rem' }}
                        onClick={handleLogout}
                    >
                        <ListItemIcon>
                            <ExitToAppOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Sign Out'} />
                    </ListItem>
                    <Typography
                        variant="subtitle1"
                        style={{ padding: '2rem 3rem', color: '#F5F5F5' }}
                    >
                        © {year} Bravos. All rights reserved.
                    </Typography>
                </Box>
            </Drawer>
            <main className={classes.content}>{children}</main>
        </div>
    )
}

export default withRouter(Layout)
