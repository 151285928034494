import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { InputLabel } from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import { useState } from 'react'

const YearSelector = (props) => {
    const { onChange, options } = props

    const [year, setYear] = useState(null)

    const handleChange = (date) => {
        setYear(date.format('YYYY'))
        onChange(date.format('YYYY'))
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <InputLabel id="year-selector-select-label">
                Year Selector
            </InputLabel>
            <br />
            <br />
            <DatePicker
                variant="inline"
                label={options.label}
                openTo="year"
                views={['year']}
                autoOk={true}
                value={year}
                onChange={(date) => handleChange(date)}
            />
        </MuiPickersUtilsProvider>
    )
}
export default YearSelector
