import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getNetworkModelTimestamps } from '../../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const NetworkModelTimestampSelect = (props) => {
    const { onChange, formContext } = props
    const [networkModelTimestamps, setNetworkModelTimestamps] = useState([])

    const [timestamp, setTimestamp] = useState('')

    const classes = useStyles()

    const handleChange = (event) => {
        let tStamp = event.target.value
        setTimestamp(tStamp)
        onChange(tStamp)
    }

    useEffect(() => {
        const getTimestamps = async () => {
            const res = await getNetworkModelTimestamps()
            if (res.error !== null) {
                // TODO: Figure how to inject errors into RSJF validation
                console.error('Error retrieving network model timestamps.')
            } else {
                setNetworkModelTimestamps(res.data)
            }
        }

        getTimestamps().catch(console.error)
    }, [formContext])
    return (
        <div>
            {networkModelTimestamps.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel id="network-model-timestamp-select-label">
                        Network Model Timestamps
                    </InputLabel>
                    <Select
                        labelId="network-model-timestamp-select-label"
                        id="network-model-timestamp-select"
                        value={timestamp}
                        onChange={handleChange}
                    >
                        {networkModelTimestamps.map((tStamp) => (
                            <MenuItem value={tStamp} key={tStamp}>
                                {tStamp.toString()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>No Network Model Timestamps available to choose</div>
            )}
        </div>
    )
}

export default NetworkModelTimestampSelect
