import axios from 'axios'

export const PipelineFileUploadJobTypes = {
    1: 'DamLoopGDXUpload',
}

export const PipelineFileUploadJobStatus = {
    1: 'Uploading',
    2: 'Complete',
    3: 'Failed',
}

export async function pipelineUploadFile(pipelineId, pipelineName, fileData) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'POST',
            url: `/pipeline/upload_file/${pipelineId}?pipeline_name=${pipelineName}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: fileData,
            timeout: 0,
        })
        resolved.status = res.status
        resolved.data = res.data
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function deleteUploadedFile(uploadedFileId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'DELETE',
            url: `/pipeline/upload_file/${uploadedFileId}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            timeout: 0,
        })
        resolved.status = res.status
        resolved.data = res.data
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getPipelineUploadFiles(
    pipelineId,
    pipelineName,
    fileUploadType
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/uploaded_files/${pipelineId}?pipeline_name=${pipelineName}&file_upload_type=${fileUploadType}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getDAMLoopScenariosByPipeline(pipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/get_scenarios_from_gdx_files/${pipelineId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMMDPScenarios(pipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/mini_master_pipeline/get_mmdp_scenarios/${pipelineId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getDAMAuctionMonthAndYear(sequence) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/search_auction_month_and_year/${sequence}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMiniMasterRunVersion(pipeline_id) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/mini_master_pipeline/get_run_versions/${pipeline_id}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMiniMasterDistinctSourcesAndSinks(pipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/mini_master_pipeline/distinct_sources_and_sinks/${pipelineId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMiniMasterPathData(
    pipelineId,
    scenarioId,
    source,
    sink
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/mini_master_pipeline/path_data/${pipelineId}/${scenarioId}?source=${source}&sink=${sink}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMiniMasterContributionsForPath(
    pipelineId,
    scenarioId,
    source,
    sink,
    type
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/mini_master_pipeline/contributions_for_path/${pipelineId}/${scenarioId}?source=${source}&sink=${sink}&otype=${type}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMiniMasterRawDamAnalysisByScenario(
    pipelineId,
    scenarioId
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }
    const token_config = {
        headers: {
            Authorization: `Bearer ${process.env.JWD_TOKEN}`,
        },
    }

    try {
        const result_responses = await axios.all([
            axios.get(
                `/mini_master_pipeline/raw_dam_analysis/dual_within_frac_line_limit_buy/${pipelineId}/${scenarioId}`,
                token_config
            ),
            axios.get(
                `/mini_master_pipeline/raw_dam_analysis/dual_within_frac_line_limit_sell/${pipelineId}/${scenarioId}`,
                token_config
            ),
            axios.get(
                `/mini_master_pipeline/raw_dam_analysis/dual_rest_within_frac_of_budget_buy/${pipelineId}/${scenarioId}`,
                token_config
            ),
            axios.get(
                `/mini_master_pipeline/raw_dam_analysis/dual_rest_within_frac_of_budget_sell/${pipelineId}/${scenarioId}`,
                token_config
            ),
            axios.get(
                `/mini_master_pipeline/raw_dam_analysis/dual_max_exposure/${pipelineId}/${scenarioId}`,
                token_config
            ),
        ])
        const visualizations = []
        for (var i = 0; i < result_responses.length; i++) {
            visualizations.push(result_responses[i].data)
        }
        resolved.data = visualizations
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getMiniMasterRawCRRAnalysisByScenario(
    pipelineId,
    scenarioId
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }
    const token_config = {
        headers: {
            Authorization: `Bearer ${process.env.JWD_TOKEN}`,
        },
    }

    try {
        const result_responses = await axios.all([
            axios.get(
                `/mini_master_pipeline/raw_crr_analysis/dual_within_line_limit_buy/${pipelineId}/${scenarioId}`,
                token_config
            ),
            axios.get(
                `/mini_master_pipeline/raw_crr_analysis/dual_within_line_limit_sell/${pipelineId}/${scenarioId}`,
                token_config
            ),
            axios.get(
                `/mini_master_pipeline/raw_crr_analysis/dual_rest_within_frac_of_budget/${pipelineId}/${scenarioId}`,
                token_config
            ),
        ])
        const visualizations = []
        for (var i = 0; i < result_responses.length; i++) {
            visualizations.push(result_responses[i].data)
        }
        resolved.data = visualizations
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function addSelectedMiniMasterScenario(
    pipeline_id,
    selected_scenario
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'PUT',
            url: `/mini_master_pipeline/add_selected_minimaster_scenario/${pipeline_id}/${selected_scenario}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function cancelOrRestartPipeline(
    pipeline_name,
    action,
    pipeline_id
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios.post(
            `/pipeline/${action}/${pipeline_id}?pipeline_name=${pipeline_name}`
        )
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function resetPostProcPipelineTasks(pipeline_id) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'POST',
            url: `/mini_master_pipeline/reset_post_proc_pipeline_tasks/${pipeline_id}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function createDAMLoopScenario(damLoopScenario) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'POST',
            url: `/dam_loop`,
            timeout: 0,
            data: damLoopScenario,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function deleteDAMLoopScenario(damLoopScenarioId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'DELETE',
            url: `/dam_loop/${damLoopScenarioId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function getDAMLoopScenarios(data_extraction_pipeline_id) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_loop/${data_extraction_pipeline_id}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function updateDAMLoopInterval(interval) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'PUT',
            url: `/dam_loop/interval`,
            timeout: 0,
            data: interval,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function uploadCustomDAMLoopFile(file, pipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'POST',
            url: `/dam_loop/custom_artifacts_file_upload?pipelineId=${pipelineId}`,
            timeout: 0,
            data: file,
            headers: {
                'Content-Type': 'application/zip',
            },
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function getIntervalGdxFiles(intervalId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_loop/interval_gdx_files/${intervalId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function getPresignedUrl(key, clientMethod) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/get_presigned_url?key=${key}&clientMethod=${clientMethod}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function getIntervalTimeline(intervalId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_loop/interval_timeline/${intervalId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function getAllEmptyPipelineSettingsFiles(settings_type) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline_settings/download?settings_type=${settings_type}`,
            timeout: 0,
            responseType: 'blob', // Set the response type to 'blob'
        })

        // Trigger the file download
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${settings_type}_empty_files.zip`)
        document.body.appendChild(link)
        link.click()

        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response ? error.response.status : null
        resolved.error = error
        resolved.data = error.response ? error.response.data : null
    }
    return resolved
}

export async function getCompletedAuctionModelRounds(auctionModelPipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/auction_model/get_completed_rounds/${auctionModelPipelineId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getAuctionModelMatchingPredecessorPipelines(
    auctionIIPipelineId
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/auction_model/matching_predecessor_pipeline/${auctionIIPipelineId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getNetworkModelTimestamps() {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/network_model_timestamps`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getDefaultAdvancedDAMLoopSettings() {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_loop/default_advanced_dam_loop_settings`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
        resolved.data = error.response.data
    }
    return resolved
}

export async function sendUserVerificationEmail(userId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'POST',
            url: '/user_management/verification_email',
            data: { userId: userId },
            timeout: 0,
        })
        resolved.status = res.status
        resolved.data = res.data
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

// --------------
// For DAM 3
// --------------

export async function getScenariosForFlowDecomp(pipelineId, runId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/scenarios_for_flow_decomp/${pipelineId}/${runId}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getConstraintsForFlowDecomp(pipelineId, runId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/constraints_for_flow_decomp/${pipelineId}/${runId}/`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getFlowDecompChartData(
    pipelineId,
    runId,
    scenarioId,
    chartType,
    rho,
    gamma,
    d
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/flow_decomposition_chart/${pipelineId}/${runId}/${scenarioId}/${chartType}/?rho=${rho}&gamma=${gamma}&d=${d}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getFDConstraintInfoChart(
    pipelineId,
    runId,
    scenarioId,
    rho,
    gamma,
    d
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/flow_decomposition_constraint_info_chart/${pipelineId}/${runId}/${scenarioId}/?rho=${rho}&gamma=${gamma}&d=${d}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getFlowDecompConstraintSuggestions(pipelineId, term) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/constraint_suggestions_for_fd/${pipelineId}/${term}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getFlowDecompContingencySuggestions(pipelineId, term) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/contingency_suggestions_for_fd/${pipelineId}/${term}/`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getDAM3Scenarios(pipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/dam3_scenarios/${pipelineId}/`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function startFlowDecompRun(pipelineId, constraints, scenarios) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'POST',
            url: `/dam_post_processing/start_fd_run/${pipelineId}/`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                scenarios: scenarios,
                constraints: constraints,
            },
            timeout: 0,
        })
        resolved.status = res.status
        resolved.data = res.data
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function get_completed_run_ids_for_fd(pipelineId) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/dam_post_processing/completed_run_ids_for_fd/${pipelineId}/`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function getDamPipelineScenariosFromDataExtractionPipelineId(
    data_extraction_pipeline_id
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }

    try {
        const res = await axios({
            method: 'GET',
            url: `/pipeline/data_extraction/get_dam_pipeline_scenarios/${data_extraction_pipeline_id}`,
            timeout: 0,
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}

export async function submitPipelineUserInputTask(
    pipelineType,
    pipelineId,
    parentArchitectureCode,
    architectureCode,
    data
) {
    const resolved = {
        data: null,
        error: null,
        status: null,
    }
    console.log(data)
    try {
        const res = await axios({
            method: 'POST',
            url: `/${pipelineType}/submit_user_input_task/${pipelineId}?architecture_code=${architectureCode}&parent_architecture_code=${parentArchitectureCode}`,
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 0,
            data: { userInputData: JSON.stringify(data) },
        })
        resolved.data = res.data
        resolved.status = res.status
    } catch (error) {
        resolved.status = error.response.status
        resolved.error = error
    }

    return resolved
}
