import {
    Grid,
    withStyles,
    Button,
    Snackbar,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { Component } from 'react'
import { withRouter } from 'react-router'
import CustomDataGrid from '../DataGrid/CustomDataGrid'
import { submitPipelineUserInputTask } from '../../../api'

const styles = (theme) => ({
    rowApprove: {
        backgroundColor: 'lightgreen !important',
    },
    rowMaybe: {
        backgroundColor: 'lightyellow !important',
    },
    rowReject: {
        backgroundColor: 'lightcoral !important',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const APPROVE = 'approve'
const MAYBE = 'maybe'
const REJECT = 'reject'

class CompareTables extends Component {
    constructor(props) {
        super(props)

        const {
            readData,
            primaryInputTable,
            pipelineId,
            parentArchitectureCode,
            architectureCode,
            additionalColumns,
        } = props
        this.pipelineId = pipelineId
        this.parentArchitectureCode = parentArchitectureCode
        this.architectureCode = architectureCode
        this.additionalColumns = additionalColumns

        const primaryTableName = Object.keys(primaryInputTable)[0]

        // Add additional columns to each row
        primaryInputTable[primaryTableName].forEach((row) => {
            Object.keys(this.additionalColumns).forEach((column) => {
                row[column] = this.additionalColumns[column]
            })
        })

        // Reorder columns to prioritize additionalColumns
        primaryInputTable[primaryTableName] = primaryInputTable[
            primaryTableName
        ].map((row) => {
            const reorderedRow = Object.assign(
                {},
                ...Object.keys(this.additionalColumns).map((column) => ({
                    [column]: row[column],
                })),
                ...Object.keys(row)
                    .filter(
                        (key) =>
                            !Object.keys(this.additionalColumns).includes(key)
                    )
                    .map((key) => ({ [key]: row[key] }))
            )
            return reorderedRow
        })

        // Initialize columnVisibility to make all columns visible by default
        const createInitialColumnVisibility = (table) => {
            return Object.keys(table[0] || {}).reduce((acc, field) => {
                acc[field] = true // Set to false to indicate visible
                return acc
            }, {})
        }

        const initialColumnVisibility = createInitialColumnVisibility(
            primaryInputTable[primaryTableName]
        )

        this.state = {
            compareData: readData,
            selections: {},
            visibleTables: [],
            filters: {},
            columnVisibility: {
                [primaryTableName]: initialColumnVisibility,
                ...readData.reduce((acc, table) => {
                    const tableName = Object.keys(table)[0]
                    acc[tableName] = createInitialColumnVisibility(
                        table[tableName]
                    )
                    return acc
                }, {}),
            },
            primaryTable: primaryInputTable,
            primaryTableName: primaryTableName,
            selectedPrimaryRow: {},
            selectedCompareRow: {},
        }
        this.handleRowUpdate = this.handleRowUpdate.bind(this)
    }

    showAlert(message) {
        this.setState({
            alertOpen: true,
            alertMessage: message,
        })
    }

    generateRows = (tableData, tableName) => {
        return tableData[tableName].map((row, index) => ({
            ...row,
            id: row.id || `${index}`,
        }))
    }

    generateColumns = (tableData, tableName, isEditable = false) => {
        return Object.keys(tableData[tableName][0]).map((field) => ({
            field,
            headerName: field.charAt(0) + field.slice(1),
            width: field === 'notes' || field === 'decision' ? 300 : 150,
            editable: isEditable,
            renderCell:
                field === 'decision'
                    ? (params) => (
                          <>
                              <Checkbox
                                  checked={
                                      this.state.selections[params.row.id] ===
                                      'approve'
                                  }
                                  onChange={() =>
                                      this.handleRowUpdate({
                                          ...params,
                                          value: 'approve',
                                      })
                                  }
                              />{' '}
                              Approve
                              <Checkbox
                                  checked={
                                      this.state.selections[params.row.id] ===
                                      'maybe'
                                  }
                                  onChange={() =>
                                      this.handleRowUpdate({
                                          ...params,
                                          value: 'maybe',
                                      })
                                  }
                              />{' '}
                              Maybe
                              <Checkbox
                                  checked={
                                      this.state.selections[params.row.id] ===
                                      'reject'
                                  }
                                  onChange={() =>
                                      this.handleRowUpdate({
                                          ...params,
                                          value: 'reject',
                                      })
                                  }
                              />{' '}
                              Reject
                          </>
                      )
                    : undefined,
        }))
    }

    handleFilterChange = (tableName, field, value) => {
        this.setState((prevState) => ({
            filters: {
                ...prevState.filters,
                [tableName]: {
                    ...prevState.filters[tableName],
                    [field]: value,
                },
            },
        }))
    }

    handleColumnVisibilityChange = (tableName, field) => {
        this.setState((prevState) => ({
            columnVisibility: {
                ...prevState.columnVisibility,
                [tableName]: {
                    ...prevState.columnVisibility[tableName],
                    [field]: !prevState.columnVisibility[tableName]?.[field],
                },
            },
        }))
    }

    handleRowUpdate = (params) => {
        const { id, field, value } = params
        this.setState((prevState) => {
            const primaryTableName = prevState.primaryTableName
            const updatedRows = prevState.primaryTable[primaryTableName].map(
                (row, index) => {
                    if (index === parseInt(id)) {
                        return { ...row, [field]: value }
                    }
                    return row
                }
            )

            const updatedSelections =
                field === 'decision'
                    ? {
                          ...prevState.selections,
                          [id]: prevState.selections[id] === value ? '' : value,
                      }
                    : prevState.selections

            return {
                primaryTable: {
                    ...prevState.primaryTable,
                    [primaryTableName]: updatedRows,
                },
                selections: updatedSelections,
            }
        })
    }

    highlightSelectedRow = (params) => {
        const selection = this.state.selections[params.row.id]
        switch (selection) {
            case APPROVE:
                return this.props.classes.rowApprove
            case MAYBE:
                return this.props.classes.rowMaybe
            case REJECT:
                return this.props.classes.rowReject
            default:
                return ''
        }
    }

    handleVisibleTablesChange = (event) => {
        this.setState({
            visibleTables: [...event.target.value],
        })
    }

    handleSelectionChange = (rowType, newSelection, compareTableName = '') => {
        const selectedId = newSelection[0]
        if (rowType === 'selectedPrimaryRow') {
            const selectedRow = this.state.primaryTable[
                this.state.primaryTableName
            ].find((row, index) => index === parseInt(selectedId))

            this.setState({
                selectedPrimaryRow: {
                    index: parseInt(selectedId),
                    row: selectedRow,
                },
            })
        } else {
            const selectedRow = this.state.compareData
                .find((table) => Object.keys(table)[0] === compareTableName)
                [compareTableName].find(
                    (row, index) => index === parseInt(selectedId)
                )

            this.setState({
                selectedCompareRow: {
                    index: parseInt(selectedId),
                    row: selectedRow,
                },
            })
        }
    }

    handleCopyData = () => {
        const { selectedCompareRow, selectedPrimaryRow } = this.state

        if (selectedCompareRow && selectedPrimaryRow) {
            const updatedRow = this.state.primaryTable[
                this.state.primaryTableName
            ].map((row, index) => {
                if (index === selectedPrimaryRow.index) {
                    Object.keys(row).forEach((key) => {
                        // Override primaryTable cell with selectedCompareRow value
                        // Only copy data from columns that exist in the PrimaryTable
                        if (selectedCompareRow.row.hasOwnProperty(key)) {
                            row[key] = selectedCompareRow.row[key]
                        }
                    })
                }
                return row
            })

            this.setState((prevState) => ({
                primaryTable: {
                    ...prevState.primaryTable,
                    [prevState.primaryTableName]: updatedRow,
                },
            }))
        }
    }

    handleSubmit = async () => {
        // Check if there are any empty decision rows in the primaryTable
        const hasEmptyDecisionRows = this.state.primaryTable[
            this.state.primaryTableName
        ].some((row) => row.decision === '')
        if (hasEmptyDecisionRows) {
            this.showAlert(
                'There are rows with empty decisions. Please fill in all decisions before submitting.'
            )
            return
        }
        const primaryTableRows =
            this.state.primaryTable[this.state.primaryTableName]
        try {
            const res = await submitPipelineUserInputTask(
                'mno_pipeline',
                this.pipelineId,
                this.parentArchitectureCode,
                this.architectureCode,
                primaryTableRows
            )
            if (res.error !== null) {
                this.showAlert(res.error.response.data)
            } else {
                this.props.onTaskComplete()
            }
        } catch (error) {
            this.showAlert('Error submitting pipeline user input task:', error)
        }
    }

    handleAddRow = () => {
        const newRow = {
            id: this.state.primaryTable[
                this.state.primaryTableName
            ].length.toString(), // Unique ID
            decision: '', // Default value
            notes: '', // Default value
            // Add other fields as necessary
        }

        this.setState((prevState) => ({
            primaryTable: {
                ...prevState.primaryTable,
                [prevState.primaryTableName]: [
                    ...prevState.primaryTable[prevState.primaryTableName],
                    newRow,
                ],
            },
        }))
    }

    render() {
        const {
            compareData,
            primaryTable,
            primaryTableName,
            visibleTables,
            filters,
            columnVisibility,
        } = this.state

        return (
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <FormControl style={{ marginBottom: '10px' }}>
                        <label>Compare Tables</label>
                        <Select
                            multiple
                            value={visibleTables}
                            onChange={this.handleVisibleTablesChange}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {compareData
                                .map((table) => Object.keys(table)[0])
                                .map((tableName) => (
                                    <MenuItem key={tableName} value={tableName}>
                                        <Checkbox
                                            checked={visibleTables.includes(
                                                tableName
                                            )}
                                        />
                                        {tableName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={visibleTables.length > 0 ? 6 : 12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h2>{primaryTableName}</h2>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{ marginTop: '20px', textAlign: 'right' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleAddRow}
                            >
                                Add Row
                            </Button>
                            {visibleTables.length > 0 && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleCopyData}
                                    disabled={
                                        !this.state.selectedCompareRow ||
                                        !this.state.selectedPrimaryRow
                                    }
                                >
                                    Fill selected row data
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <CustomDataGrid
                            rows={this.generateRows(
                                primaryTable,
                                primaryTableName
                            )}
                            columns={this.generateColumns(
                                primaryTable,
                                primaryTableName,
                                true
                            )}
                            getRowClassName={(params) =>
                                this.highlightSelectedRow(params)
                            }
                            filters={filters[primaryTableName] || {}}
                            onFilterChange={(field, value) =>
                                this.handleFilterChange(
                                    primaryTableName,
                                    field,
                                    value
                                )
                            }
                            columnVisibility={
                                columnVisibility[primaryTableName]
                            }
                            onColumnVisibilityChange={(field) =>
                                this.handleColumnVisibilityChange(
                                    primaryTableName,
                                    field
                                )
                            }
                            onCellEditCommit={this.handleRowUpdate}
                            onSelectionModelChange={(newSelection) => {
                                this.handleSelectionChange(
                                    'selectedPrimaryRow',
                                    newSelection
                                )
                            }}
                        />
                    </div>
                </Grid>
                {visibleTables.map((key, index) => (
                    <Grid item xs={6} key={index}>
                        <h2>{key}</h2>
                        <CustomDataGrid
                            key={visibleTables.join(',')}
                            rows={this.generateRows(
                                compareData.find(
                                    (table) => Object.keys(table)[0] === key
                                ),
                                key
                            )}
                            columns={this.generateColumns(
                                compareData.find(
                                    (table) => Object.keys(table)[0] === key
                                ),
                                key
                            )}
                            filters={filters[key] || {}}
                            onFilterChange={(field, value) =>
                                this.handleFilterChange(key, field, value)
                            }
                            columnVisibility={columnVisibility[key]}
                            onColumnVisibilityChange={(field) =>
                                this.handleColumnVisibilityChange(key, field)
                            }
                            onSelectionModelChange={(newSelection) => {
                                this.handleSelectionChange(
                                    'selectedCompareRow',
                                    newSelection,
                                    key
                                )
                            }}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>

                <Snackbar
                    open={this.state.alertOpen}
                    autoHideDuration={6000}
                    onClose={(e, r) => this.setState({ alertOpen: false })}
                >
                    <Alert
                        onClose={(e, r) => this.setState({ alertOpen: false })}
                        severity="error"
                    >
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </Grid>
        )
    }
}

export default withRouter(withStyles(styles)(CompareTables))
