import {
    Box,
    Button,
    Grid,
    withStyles,
    List,
    ListItem,
    CircularProgress,
    Snackbar,
    Typography,
    Modal,
    Backdrop,
    Fade,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import { Component } from 'react'
import Layout from '../Layout'
import { withRouter } from 'react-router'
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import TaskChart from './Components/TaskChart'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ConfirmationDialog from './Components/ConfirmationDialog'
import CancelOrRestart from './Components/CancelOrRestart'
import PipelineAlert from './Components/PipelineAlert'
import { PipelineFileUploadJobTypes } from './../api'
import CreatePipeline from './Components/CreatePipeline'
import PipelineSettings from './Components/PipelineSettings'
import FileUploadWidget from './Components/FileUploadWidget'
import PipelineNotesForm from './Components/PipelineNotesForm'
import DAMLoopWidget from './Components/DAMLoop/DAMLoopWidget'
import { SocketContext, SocketProvider } from '../WebSockets/SocketContext'
import SelectMiniMasterRunVersion from './Components/SelectMiniMasterRunVersion'
import { convertToLocalTime } from '../Utils/TimeUtil'
import AddRunScenario from './Components/AddRunScenario'
import CompareTables from './Components/UserInputTasks/CompareTables'

const styles = (theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordionGrid: {
        width: '95%',
    },
    gridItem: {
        padding: '5px',
    },
    addButton: {
        float: 'right',
        margin: '8px',
    },
    gridButton: {
        padding: '15px',
    },
    accordionContentGrid: {
        padding: '10px',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        padding: '1rem 10px',
    },
    alertsGrid: {
        width: '100%',
    },
    compareGrid: {
        height: '100%',
        width: '100%',
    },
    paper: {
        minWidth: '85%',
        maxHeight: '85%',
        borderRadius: '10px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 5),
        overflow: 'scroll',
    },
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Pipeline extends Component {
    static contextType = SocketContext

    constructor(props) {
        super(props)
        const id = this.props.match.params.id

        this.state = {
            id: id,
            pipeline: {
                run_settings: {},
            },
            alertOpen: false,
            alertMessage: '',
            pipeline_tasks: [],
            loading: false,
            modal: false,
            activeModalView: 'visualizations',
            selectedTask: {},
            userInputTask: {},
            pipelineAction: null,
            dialogOpen: false,
            creatingScenarios: this.props.creatingScenarios || false,
            defaultDAMScenarioComplete: false,
        }

        this.setLoading = this.setLoading.bind(this)
        this.showAlert = this.showAlert.bind(this)
        this.refreshPage = this.refreshPage.bind(this)
        this.itemInvalidFromDictionary =
            this.itemInvalidFromDictionary.bind(this)
        this.handleWebSocket = this.handleWebSocket.bind(this)
    }

    itemInvalidFromDictionary = (dictionaryIn, keyInDict) => {
        return !(
            dictionaryIn === null ||
            dictionaryIn === undefined ||
            Object.keys(dictionaryIn).length === 0 ||
            dictionaryIn[keyInDict] === null
        )
    }

    handleModal = () => {
        this.setState({ modal: !this.state.modal })
    }

    handleModalViewChange = (view) => {
        this.setState({ activeModalView: view })
    }

    selectTask = (task) => {
        this.setState({ selectedTask: task })
        this.handleModal()
    }

    setLoading(loading) {
        this.setState({ loading: loading })
    }

    showAlert(message) {
        this.setState({
            alertOpen: true,
            alertMessage: message,
        })
    }

    refreshPage() {
        this.props.history.go(0)
    }

    async handleFileDownloadClick() {
        this.setState({ loading: true })
        let res = null
        let urlMethod = null

        // First, try to get the url in case of being available
        var filename = 'pipeline_results.zip'

        let signedArtifactsDownloadUrl = `/pipeline/get_url_for_artifacts/${this.state.id}?pipeline_name=${this.props.pipeline_name}`
        let serverData = null
        let resForSignedArtifact = await axios.get(signedArtifactsDownloadUrl)
        if (resForSignedArtifact.status === 200) {
            serverData = resForSignedArtifact.data
            if (this.itemInvalidFromDictionary(serverData, 'presigned_url')) {
                // if url is available
                const link = document.createElement('a')
                link.href = serverData['presigned_url']
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                urlMethod = true
            } else {
                urlMethod = false
            }
        } else {
            urlMethod = false
        }
        if (!urlMethod) {
            // if the url is not available then the backend should do it instead
            this.showAlert('S3 url not available. Downloading from backend..')
            try {
                res = await axios({
                    url: `/pipeline/download_artifacts/${this.state.id}?pipeline_name=${this.props.pipeline_name}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        'Content-Disposition': 'attachment',
                    },
                    timeout: 600000,
                })
            } catch (error) {
                this.setState({ loading: false })
                this.showAlert('Failed to download Artifacts!')
                return
            }
            var disposition = res.headers['content-disposition']
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
            const downloadURL = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = downloadURL
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        }

        this.setState({ loading: false })
    }

    async componentDidMount() {
        if (
            this.state.id !== undefined &&
            !this.props.location.pathname.includes('create/scenarios')
        ) {
            this.setState({ loading: true })

            await this.getPipelineTasks()

            this.setState({ loading: false })
        }
        await this.handleWebSocket()

        if (
            this.state.id !== undefined &&
            this.props.pipeline_name === 'data_extraction_pipeline'
        ) {
            await this.defaultScenarioHasCompletedDate()
        }
    }

    async handleWebSocket() {
        const socket = this.context

        if (socket) {
            // Listen for data from the server
            socket.onMessage('pipeline_tasks', (incomingData) => {
                if (
                    this.props.pipeline_name === incomingData.pipeline_name &&
                    this.state.id === incomingData.pipeline_id
                ) {
                    this.setState({
                        pipeline_tasks: incomingData.pipeline_tasks,
                    })
                }
            })

            socket.onMessage('pipeline_info', (incomingData) => {
                if (
                    this.props.pipeline_name === incomingData.pipeline_name &&
                    this.state.id === incomingData.pipeline_id
                ) {
                    this.setState({
                        pipeline: incomingData.pipeline,
                    })
                }
            })
        }
    }

    componentWillUnmount() {
        const socket = this.context
        if (socket) {
            socket.off('pipeline_tasks')
            socket.off('pipeline_info')
        }
    }

    async getPipelineTasks() {
        const results = await axios.get(
            `/pipeline/pipeline_tasks/${this.state.id}?pipeline_name=${this.props.pipeline_name}`
        )

        const pipeline_results = await axios.get(
            `/pipeline/${this.state.id}?pipeline_name=${this.props.pipeline_name}`
        )
        this.setState({
            pipeline_tasks: results.data.pipeline_tasks,
            pipeline: pipeline_results.data.pipeline,
        })
    }

    handlePipelineAction = async (action) => {
        this.setState({ loading: true })
        try {
            if (action === 'add scenarios') {
                this.props.history.push({
                    pathname: `/${this.props.pipeline_name}/create/scenarios/${this.state.id}`,
                })
                return
            }
            const res = await axios.post(
                `/pipeline/${action}/${this.state.id}?pipeline_name=${this.props.pipeline_name}`
            )

            if (res.data.message !== 'Success') {
                this.showAlert(res.data.message)
            } else {
                this.refreshPage()
            }
        } catch (error) {
            if (error.response.status === 400) {
                this.showAlert(`Error: ${error.response.data}`)
            } else {
                this.showAlert(
                    `Failed to ${action} pipeline. Contact an admin for more info.`
                )
            }
        }
        this.setState({ loading: false })
    }

    handlePipelineActionClick = (action) => {
        this.setState({
            dialogOpen: true,
            pipelineAction: action,
        })
    }

    handleGetUserInputTask = async (architectureCode) => {
        this.setState({ loading: true })
        try {
            let getUrl = `/mno_pipeline/user_input_task/${this.state.id}?architecture_code=${architectureCode}`
            const res = await axios.get(getUrl)
            if (res.status === 200) {
                // Validate if JSON is valid before setting userInputTask
                try {
                    if (res.data.payload !== 'complete') {
                        JSON.parse(JSON.stringify(res.data))
                    }
                    this.setState({ userInputTask: res.data })
                } catch (error) {
                    this.setState({
                        userInputTask: {
                            payload: 'invalid json',
                        },
                    })
                    this.showAlert(`Invalid JSON: ${error}`)
                }
            }
        } catch (error) {
            if (error.response.status === 400) {
                this.showAlert(`Error: ${error.response.data}`)
            } else {
                this.showAlert(
                    `Failed to get user input task. Contact an admin for more info.`
                )
            }
        }
        this.setState({ loading: false })
    }

    handleClose = (newValue) => {
        this.setState({ dialogOpen: false })

        if (newValue) {
            this.handlePipelineAction(this.state.pipelineAction)
        } else {
            this.setState({ pipelineAction: null })
        }
    }
    defaultScenarioHasCompletedDate = async () => {
        const res = await axios.get(
            `/pipeline/data_extraction/default_scenario_has_completed_date/${this.state.id}`
        )
        this.setState({ defaultDAMScenarioComplete: res.data === true })
    }

    render() {
        const { history, classes, pipeline_name } = this.props
        return (
            <Layout>
                {this.state.loading && (
                    <div className={classes.loadingIndicator}>
                        <CircularProgress color="primary" size={75} />
                    </div>
                )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'data_transformation_pipeline' && (
                        // TODO: Use this across all pipelines
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/DataTransformationPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/DataTransformationPipeline/Schema.json')}
                        />
                    )}
                {this.state.id !== undefined &&
                    !this.state.loading &&
                    pipeline_name === 'data_transformation_pipeline' &&
                    this.state.creatingScenarios && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            pipeline_id={this.state.id}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/DataTransformationPipeline/UiRunScenarioSchema.json')}
                            schema={require('./Components/Schemas/DataTransformationPipeline/RunScenarioSchema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'data_extraction_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/DataExtractionPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/DataExtractionPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'dam_post_processing_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/DAMPostProcessingPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/DAMPostProcessingPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'auction_pipeline' && (
                        // TODO: Use this across all pipelines
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/AuctionPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/AuctionPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'auction_ii_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/AuctionIIPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/AuctionIIPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'auction_model_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/AuctionModelPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/AuctionModelPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'master_model_data_prep_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/MasterModelDataPrepPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/MasterModelDataPrepPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'mini_master_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/MiniMasterPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/MiniMasterPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'portfolio_stats_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/PortfolioStatsPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/PortfolioStatsPipeline/Schema.json')}
                        />
                    )}
                {this.state.id === undefined &&
                    !this.state.loading &&
                    pipeline_name === 'mno_pipeline' && (
                        <CreatePipeline
                            pipeline_name={pipeline_name}
                            history={history}
                            setLoading={this.setLoading}
                            showAlert={this.showAlert}
                            uiSchema={require('./Components/Schemas/MNOPipeline/UiSchema.json')}
                            schema={require('./Components/Schemas/MNOPipeline/Schema.json')}
                        />
                    )}

                {this.state.id !== undefined &&
                    !this.state.loading &&
                    !this.state.creatingScenarios && (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={10}>
                                        <Typography variant="h3">
                                            {this.state.pipeline.id} -{' '}
                                            {this.state.pipeline.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {pipeline_name ===
                                            'data_transformation_pipeline' && (
                                            <AddRunScenario
                                                status={
                                                    this.state.pipeline.status
                                                }
                                                pipeline_name={pipeline_name}
                                                handleAction={(event, action) =>
                                                    this.handlePipelineActionClick(
                                                        action
                                                    )
                                                }
                                                disabled={
                                                    this.state.pipeline
                                                        .status !==
                                                        'Complete' &&
                                                    this.state.pipeline
                                                        .status !== 'Cancelled'
                                                }
                                            />
                                        )}
                                        <CancelOrRestart
                                            status={this.state.pipeline.status}
                                            handleAction={(event, action) =>
                                                this.handlePipelineActionClick(
                                                    action
                                                )
                                            }
                                        />
                                        {this.state.pipeline
                                            .artifacts_available && (
                                            <IconButton
                                                disabled={
                                                    ![
                                                        'Complete',
                                                        'Complete (archived)',
                                                    ].includes(
                                                        this.state.pipeline
                                                            .status
                                                    )
                                                }
                                                color="primary"
                                                onClick={async () => {
                                                    await this.handleFileDownloadClick()
                                                }}
                                            >
                                                <Tooltip
                                                    title="Download Pipeline Artifacts"
                                                    placement="bottom-start"
                                                >
                                                    <CloudDownloadIcon />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    style={{ margin: '20px 0 2rem 10px' }}
                                >
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="secondary"
                                        >
                                            Created:{' '}
                                            {convertToLocalTime(
                                                this.state.pipeline.created_date
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="secondary"
                                        >
                                            Creator:{' '}
                                            {this.state.pipeline.created_by}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body1"
                                            color="secondary"
                                        >
                                            Status: {this.state.pipeline.status}
                                        </Typography>
                                    </Grid>
                                    {this.state.id !== undefined &&
                                        !this.state.loading &&
                                        pipeline_name ===
                                            'mini_master_pipeline' &&
                                        this.state.pipeline.status ===
                                            'Complete' && (
                                            <Grid item xs={3}>
                                                <SelectMiniMasterRunVersion
                                                    pipelineId={this.state.id}
                                                    showAlert={this.showAlert}
                                                    refreshPage={
                                                        this.refreshPage
                                                    }
                                                    pipelineName={pipeline_name}
                                                    setLoading={this.setLoading}
                                                />
                                            </Grid>
                                        )}
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                    alignContent="space-between"
                                >
                                    <PipelineSettings
                                        runSettings={
                                            this.state.pipeline.run_settings
                                        }
                                        classes={classes}
                                        pipelineName={pipeline_name}
                                    />
                                    <PipelineNotesForm
                                        pipelineType={pipeline_name}
                                        pipelineId={this.state.id}
                                        classes={classes}
                                    />
                                    {pipeline_name ===
                                        'data_extraction_pipeline' && (
                                        <FileUploadWidget
                                            pipelineId={this.state.id}
                                            classes={classes}
                                            fileUploadHeader={
                                                PipelineFileUploadJobTypes[1]
                                            }
                                            fileUploadType={
                                                PipelineFileUploadJobTypes[1]
                                            }
                                            pipelineName={
                                                this.props.pipeline_name
                                            }
                                            acceptedFileTypes=".gdx"
                                            socket={this.context}
                                        />
                                    )}
                                    {pipeline_name ===
                                        'data_extraction_pipeline' &&
                                        this.state
                                            .defaultDAMScenarioComplete && (
                                            <SocketProvider>
                                                <Grid item xs={12}>
                                                    <DAMLoopWidget
                                                        pipeline={
                                                            this.state.pipeline
                                                        }
                                                        classes={classes}
                                                        showAlert={
                                                            this.showAlert
                                                        }
                                                    ></DAMLoopWidget>
                                                </Grid>
                                            </SocketProvider>
                                        )}
                                </Grid>
                            </Grid>
                            {this.state.pipeline_tasks.map((taskList) =>
                                taskList.map((task) => (
                                    <Grid
                                        key={task.id}
                                        item
                                        xs={12}
                                        className={classes.accordionGrid}
                                    >
                                        <ListItem
                                            button
                                            divider
                                            style={{ padding: '1.2rem 0.8rem' }}
                                            disabled={
                                                !this.state.pipeline
                                                    .has_user_input_tasks &&
                                                task.alerts.length < 1 &&
                                                task.visualizations.length < 1
                                            }
                                            onClick={() =>
                                                this.selectTask(task)
                                            }
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={2}>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.heading
                                                        }
                                                    >
                                                        {task.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.heading
                                                        }
                                                    >
                                                        {task.architecture_code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.heading
                                                        }
                                                    >
                                                        {task.status}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.heading
                                                        }
                                                    >
                                                        Start:{' '}
                                                        {convertToLocalTime(
                                                            task.start_timestamp
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.heading
                                                        }
                                                    >
                                                        Elapsed:{' '}
                                                        {task.elapsed_time ||
                                                            'N/A'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            color:
                                                                task.alerts
                                                                    .length ===
                                                                    0 ||
                                                                task.alerts.every(
                                                                    (alert) =>
                                                                        alert.alert_level ===
                                                                        'Info'
                                                                )
                                                                    ? 'black'
                                                                    : 'red',
                                                        }}
                                                        className={
                                                            classes.heading
                                                        }
                                                    >
                                                        Alerts:{' '}
                                                        {task.alerts.length}
                                                    </Typography>
                                                </Grid>
                                                {task.scenario_id &&
                                                    this.state.pipeline
                                                        .run_settings
                                                        .run_scenarios !==
                                                        undefined &&
                                                    this.state.pipeline.run_settings.run_scenarios.some(
                                                        (scenario) =>
                                                            scenario.id ===
                                                                task.scenario_id &&
                                                            scenario.default !==
                                                                true
                                                    ) && (
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                variant="body2"
                                                                className={
                                                                    classes.heading
                                                                }
                                                            >
                                                                Scenario:{' '}
                                                                {this.state.pipeline.run_settings.run_scenarios.find(
                                                                    (
                                                                        scenario
                                                                    ) =>
                                                                        scenario.id ===
                                                                        task.scenario_id
                                                                )?.name ||
                                                                    task.scenario_id}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        </ListItem>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.modal}
                    onClose={this.handleModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modal}>
                        <div className={classes.paper}>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                className={classes.alertsGrid}
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h4"
                                        color="primary"
                                        id="transition-modal-title"
                                        className={classes.modalTitle}
                                    >
                                        {this.state.selectedTask.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                            mb: 2,
                                        }}
                                    >
                                        <Button
                                            variant={
                                                this.state.activeModalView ===
                                                'alerts'
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            onClick={() =>
                                                this.handleModalViewChange(
                                                    'alerts'
                                                )
                                            }
                                        >
                                            Alerts
                                        </Button>

                                        <Button
                                            variant={
                                                this.state.activeModalView ===
                                                'visualizations'
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            onClick={() =>
                                                this.handleModalViewChange(
                                                    'visualizations'
                                                )
                                            }
                                        >
                                            Visualizations
                                        </Button>

                                        {pipeline_name === 'mno_pipeline' && (
                                            <Button
                                                variant={
                                                    this.state
                                                        .activeModalView ===
                                                    'userInputTasks'
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                onClick={async () => {
                                                    this.handleModalViewChange(
                                                        'userInputTasks'
                                                    )
                                                    this.setLoading(true)
                                                    await this.handleGetUserInputTask(
                                                        this.state.selectedTask
                                                            .architecture_code
                                                    )
                                                    this.setLoading(false)
                                                }}
                                            >
                                                User Input Tasks
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            {this.state.loading && (
                                <div className={classes.loadingIndicator}>
                                    <CircularProgress
                                        color="primary"
                                        size={75}
                                    />
                                </div>
                            )}
                            {this.state.activeModalView === 'alerts' && (
                                <>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        className={classes.alertsGrid}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="primary"
                                            className={classes.modalTitle}
                                        >
                                            {' '}
                                            Alerts
                                        </Typography>

                                        {this.state.selectedTask.alerts &&
                                        this.state.selectedTask.alerts.length >
                                            -1 ? (
                                            <List style={{ width: '100%' }}>
                                                {this.state.selectedTask.alerts.map(
                                                    (alert) => (
                                                        <ListItem
                                                            key={alert.id}
                                                            style={{
                                                                padding:
                                                                    '10px 5px',
                                                            }}
                                                        >
                                                            <PipelineAlert
                                                                alert={alert}
                                                            />
                                                        </ListItem>
                                                    )
                                                )}
                                            </List>
                                        ) : (
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                className={classes.alertsGrid}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={
                                                        classes.modalTitle
                                                    }
                                                >
                                                    No alerts generated for this
                                                    task.
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            )}
                            {this.state.activeModalView ===
                                'visualizations' && (
                                <>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        className={classes.alertsGrid}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="primary"
                                            className={classes.modalTitle}
                                        >
                                            {' '}
                                            Visualizations
                                        </Typography>
                                        {this.state.selectedTask
                                            .visualizations &&
                                        this.state.selectedTask.visualizations
                                            .length > 0 ? (
                                            <>
                                                {this.state.selectedTask.visualizations.map(
                                                    (visual, index) => (
                                                        <TaskChart
                                                            key={index}
                                                            visualization={
                                                                visual
                                                            }
                                                            pipelineId={
                                                                this.state.id
                                                            }
                                                            showAlert={
                                                                this.showAlert
                                                            }
                                                        />
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                className={classes.modalTitle}
                                            >
                                                No visualizations currently
                                                available for this task.
                                            </Typography>
                                        )}
                                    </Grid>
                                </>
                            )}
                            {this.state.activeModalView === 'userInputTasks' &&
                                !this.state.loading && (
                                    <>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            className={classes.compareGrid}
                                        >
                                            {this.state.userInputTask
                                                .payload !== 'complete' &&
                                            !this.state.loading ? (
                                                this.state.userInputTask
                                                    .payload ===
                                                'invalid json' ? (
                                                    <Typography
                                                        variant="body1"
                                                        color="error"
                                                        className={
                                                            classes.modalTitle
                                                        }
                                                    >
                                                        Invalid JSON returned
                                                        from API
                                                    </Typography>
                                                ) : (
                                                    <>
                                                        <Typography
                                                            variant="h6"
                                                            color="primary"
                                                            className={
                                                                classes.modalTitle
                                                            }
                                                        >
                                                            {`Task (${this.state.userInputTask.payload.index}/${this.state.userInputTask.payload.task_count}) - ${this.state.userInputTask.payload.name}`}
                                                        </Typography>
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '80%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            <CompareTables
                                                                readData={
                                                                    this.state
                                                                        .userInputTask
                                                                        .payload
                                                                        .read_data
                                                                }
                                                                primaryInputTable={
                                                                    this.state
                                                                        .userInputTask
                                                                        .payload
                                                                        .primary_input_table
                                                                }
                                                                additionalColumns={
                                                                    this.state
                                                                        .userInputTask
                                                                        .payload
                                                                        .additional_columns
                                                                }
                                                                pipelineId={
                                                                    this.state
                                                                        .id
                                                                }
                                                                parentArchitectureCode={
                                                                    this.state
                                                                        .selectedTask
                                                                        .architecture_code
                                                                }
                                                                architectureCode={
                                                                    this.state
                                                                        .userInputTask
                                                                        .payload
                                                                        .architecture_code
                                                                }
                                                                onTaskComplete={async () => {
                                                                    await this.handleGetUserInputTask(
                                                                        this
                                                                            .state
                                                                            .selectedTask
                                                                            .architecture_code
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            ) : (
                                                <Typography
                                                    variant="body1"
                                                    className={
                                                        classes.modalTitle
                                                    }
                                                >
                                                    All user input tasks
                                                    complete
                                                </Typography>
                                            )}
                                        </Grid>
                                    </>
                                )}
                        </div>
                    </Fade>
                </Modal>
                <Snackbar
                    open={this.state.alertOpen}
                    autoHideDuration={6000}
                    onClose={(e, r) => this.setState({ alertOpen: false })}
                >
                    <Alert
                        onClose={(e, r) => this.setState({ alertOpen: false })}
                        severity="error"
                    >
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
                <ConfirmationDialog
                    classes={{
                        paper: classes.paper,
                    }}
                    keepMounted
                    title={`Confirm ${this.state.pipelineAction} pipeline`}
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    dialogContent={`Are you sure you want to ${this.state.pipelineAction} pipeline ${this.state.id}?`}
                />
            </Layout>
        )
    }
}

export default withRouter(withStyles(styles)(Pipeline))
