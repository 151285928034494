import { Component } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Form from '@rjsf/material-ui'
import validator from '@rjsf/validator-ajv8'
import widgets from './Schemas/Widgets'
import ObjectFieldTemplate from './CustomTemplates/ObjectFieldTemplate'
import Notes from './Notes'
import axios from 'axios'

class CreatePipeline extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            uiSchema: props.uiSchema,
            schema: props.schema,
            notes: props.notes,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const currentFormData = event.formData
        const previousUseDefaults = this.state.formData.useDefaults

        if (!currentFormData.useDefaults && previousUseDefaults) {
            // Reset formData when useDefaults goes from true to false
            this.setState({ formData: { useDefaults: false } })
        } else {
            this.setState({ formData: currentFormData })

            if (currentFormData.useDefaults && !previousUseDefaults) {
                // Fetch default values when useDefaults goes from false to true
                this.getDefaultValues().then((defaults) => {
                    this.setState({
                        formData: { ...defaults, useDefaults: true },
                    })
                })
            }
        }
    }

    getDefaultValues = async () => {
        const { pipeline_name, showAlert } = this.props
        try {
            const res = await axios.get(
                `/pipeline/defaults/?pipeline_name=${pipeline_name}`
            )
            return res.data
        } catch (error) {
            if (error.response.status === 400) {
                showAlert(`Error: ${error.response.data}`)
            } else {
                showAlert(
                    `Failed to get pipeline defaults. Contact an admin for more info.`
                )
            }
        }
        return null
    }

    createPipeline = async (event) => {
        const { pipeline_name, history, setLoading, showAlert } = this.props
        setLoading(true)
        this.setState({ formData: event.formData })
        let data = this.state.formData
        if (this.props.pipeline_id === undefined) {
            try {
                const res = await axios.post(
                    `/pipeline/create?pipeline_name=${pipeline_name}`,
                    data
                )
                history.push(`/${pipeline_name}/${res.data.pipeline_id}`)
                history.go(0)
            } catch (error) {
                if (error.response.status === 400) {
                    showAlert(error.response.data)
                } else {
                    showAlert(
                        'An unkown error occured.  Please try again momentarily.'
                    )
                }
                setLoading(false)
            }
        } else {
            try {
                const res = await axios.post(
                    `/pipeline/create/scenarios?pipeline_name=${pipeline_name}&pipeline_id=${this.props.pipeline_id}`,
                    data
                )
                if (res.error !== null) {
                    showAlert(
                        'Failed to restart pipeline when creating a new DAM Pipeline Run Scenario.'
                    )
                }
                history.push(`/${pipeline_name}/${this.props.pipeline_id}`)
                history.go(0)
            } catch (error) {
                if (error.response.status === 400) {
                    showAlert(error.response.data)
                } else {
                    showAlert(
                        'An unkown error occured.  Please try again momentarily.'
                    )
                }
                setLoading(false)
            }
        }
    }

    render() {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h3" color="primary">
                        {this.state.schema.title}
                    </Typography>
                </Grid>

                {this.state.schema.notes?.length > 0 && (
                    <Notes notes={this.state.schema.notes} />
                )}

                <Form
                    schema={this.state.schema}
                    uiSchema={this.state.uiSchema}
                    validator={validator}
                    widgets={widgets}
                    formContext={this.state.formData}
                    formData={this.state.formData}
                    templates={{ ObjectFieldTemplate }}
                    onChange={this.handleChange}
                    onSubmit={this.createPipeline}
                    onError={this.showAlert}
                />
            </>
        )
    }
}

export default CreatePipeline
