import React, { useEffect, useState } from 'react'
import {
    getConstraintsForFlowDecomp,
    getFlowDecompChartData,
    getScenariosForFlowDecomp,
    getFDConstraintInfoChart,
    get_completed_run_ids_for_fd,
} from '../../../api'
import {
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Typography,
    TextField,
    InputLabel,
    Button,
    FormControl,
    OutlinedInput,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { DataGrid } from '@mui/x-data-grid'
import TaskChart from '../TaskChart'
import { SearchableSelect } from '../../../SearchableSelect/SearchableSelect'
import moment from 'moment'

const camel2title = (camelCase) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim()

// Function to generate columns dynamically based on the data
const generateColumns = (data, columnWidths = []) => {
    const columns = Object.keys(data[0]).map((key, index) => ({
        field: key,
        headerName: camel2title(key),
        width: columnWidths[index] || 215, // Use specified width or default to 120
        editable: false, // Set editable to false for all columns
    }))

    return columns
}
const castDataToRecords = (resData) => {
    // This function allow us to cast the data from
    // the format used in visualizations to
    // the format accepted by DataGrid
    const data = { ...resData.data }
    const xAxisLabel = data.x_axis_label
    const xCategories = data.x_axis_categories

    let result = []
    for (let ii = 0; ii < xCategories.length; ii++) {
        let tmpDict = {}
        tmpDict['id'] = ii
        tmpDict[xAxisLabel] = xCategories[ii]
        data.series.forEach((value, index) => {
            let serieName = value.name
            let serieValue = value.data[ii]
            tmpDict[serieName] = serieValue
        })
        result.push(tmpDict)
    }
    return result
}

const defaultToValue = (val, replacement, castToStr) => {
    if (val === undefined || val === null || val === '') {
        return replacement
    }
    if (castToStr) {
        return val.toString()
    }
    return val
}

const DAM3FlowDecompositionDriver = (props) => {
    const { pipelineId, showAlert } = props

    const [runIdOptions, setRunIdOptions] = useState([])
    const [runID, setRunID] = useState(-1)

    const [constraintsInformation, setConstraintsInformation] = useState([])
    const [rhoOptions, setRhoOptions] = useState([])
    const [selectedRho, setSelectedRho] = useState({ name: '', code: '' })
    const [gammaOptions, setGammaOptions] = useState([])
    const [selectedGamma, setSelectedGamma] = useState({ name: '', code: '' })
    const [dOptions, setDOptions] = useState([])
    const [selectedD, setSelectedD] = useState('')
    const [constraintType, setConstraintType] = useState('')
    const [mainSelection, setMainSelection] = useState('')
    const [secondarySelectionOptions, setSecondarySelectionOptions] = useState(
        []
    )
    const [secondarySelection, setSecondarySelection] = useState('')
    const [tertiarySelectionOptions, setTertiarySelectionOptions] = useState([])
    const [tertiarySelection, setTertiarySelection] = useState('')
    const [quaternarySelectionOptions, setQuaternarySelectionOptions] =
        useState([])
    const [quaternarySelection, setQuaternarySelection] = useState('')
    const [selectedFlowDataChart, setSelectedFlowDataChart] = useState(false)
    const [buttonFlowDataChartStyle, setButtonFlowDataChartStyle] = useState({
        backgroundColor: 'white',
        color: '#969b9e',
        border: '2px solid #757575',
        'font-size': '12px',
    })

    const [scenarioFDOptions, setScenarioFDOptions] = useState([])
    const [selectedFDScenario, setSelectedFDScenario] = useState('')

    const [chartStrConstraintInfo, setChartStrConstraintInfo] = useState('')
    const [chartStrInfo, setChartStrInfo] = useState('')

    const [chartType, setChartType] = useState('')
    const [fdChart, setFdChart] = useState(null)

    const [lastChartType, setLastChartType] = useState('')

    const [fdChartFlowInfo, setFdChartFlowInfo] = useState(null)

    const [fdTableData, setFdTableData] = useState([])

    const [loading, setLoading] = useState(false)

    const restartSelectedRho = () => {
        setSelectedRho({ name: '', code: '' })
    }

    const restartSelectedD = () => {
        setSelectedD('')
    }
    const restartSelectedType = () => {
        setConstraintType('')
    }

    const restartConstraintComponents = () => {
        setSelectedGamma({ name: '', code: '' })
        restartSelectedD()
        restartSelectedType()
    }

    useEffect(() => {
        const fetchRunIds = async () => {
            setLoading(true)
            try {
                const res = await get_completed_run_ids_for_fd(pipelineId)
                if (res.error !== null) {
                    showAlert('Failed to retrieve the run ids.')
                } else {
                    setRunIdOptions(
                        res.data.map((option) => ({
                            value: option,
                            label: option,
                        }))
                    )
                }
            } catch (error) {
                showAlert('Failed to show all the run ids.')
                console.error('Error fetching run ids:', error)
            }
            setLoading(false)
        }
        fetchRunIds()
    }, [showAlert, pipelineId])

    const handleRunIdChange = async (event) => {
        const selectedValue = event.target.value
        if (
            selectedValue === undefined ||
            selectedValue === null ||
            selectedValue === ''
        ) {
            return
        }

        restartSelectedRho()
        restartConstraintComponents()

        var runIdSelected = parseInt(selectedValue)
        setRunID(runIdSelected)
        setLoading(true)
        try {
            const res = await getConstraintsForFlowDecomp(
                pipelineId,
                runIdSelected
            )
            if (res.error !== null) {
                showAlert('Failed to return all the constraints.')
            } else {
                setConstraintsInformation(res.data)
                const uniqueRho = [
                    ...new Set(res.data.map((rho) => rho.constraint_name)),
                ]
                setRhoOptions(
                    uniqueRho.map((option) => ({
                        value: option,
                        label: option,
                    }))
                )
            }
        } catch (error) {
            showAlert('Failed to return all the constraints.')
            console.error('Error fetching options:', error)
        }

        try {
            const res = await getScenariosForFlowDecomp(
                pipelineId,
                runIdSelected
            )

            if (res.error !== null) {
                showAlert('Failed to return scenarios.')
            } else {
                setScenarioFDOptions(
                    res.data.map((option) => ({
                        value: option,
                        label: option,
                    }))
                )
            }
        } catch (error) {
            showAlert('Failed to return all the scenarios.')
            console.error('Error fetching scenarios:', error)
        }
        setLoading(false)
    }

    const handleRhoChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedRho({ name: selectedValue, code: '' })
        setLoading(true)

        const dataWithSpecRho = constraintsInformation.filter(
            (element) => element.constraint_name === selectedValue
        )

        // Empty the rest of the constraint propertied
        restartConstraintComponents()

        if (dataWithSpecRho.length === 0) {
            alert('There are no data for the constraint: ' + selectedValue)
        } else {
            // Find the real rho
            setSelectedRho({
                name: selectedValue,
                code: dataWithSpecRho[0].rho.toString(),
            })

            const uniqueGamma = [
                ...new Set(
                    dataWithSpecRho.map((element) => element.contingency_name)
                ),
            ]
            setGammaOptions(
                uniqueGamma.map((option) => ({
                    value: option,
                    label: option,
                }))
            )
        }
        setLoading(false)
    }

    const handleGammaChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedGamma({ name: selectedValue, code: '' })
        setLoading(true)

        const dataWithSpecRhoGamma = constraintsInformation.filter(
            (element) =>
                element.constraint_name === selectedRho.name &&
                element.contingency_name === selectedValue
        )

        restartSelectedD()
        restartSelectedType()
        if (dataWithSpecRhoGamma.length === 0) {
            alert('There are no data for the contingency: ' + selectedValue)
        } else {
            setSelectedGamma({
                name: selectedValue,
                code: dataWithSpecRhoGamma[0].gamma,
            })
            // The directions are stored as str.
            const uniqueD = [
                ...new Set(
                    dataWithSpecRhoGamma.map((element) => element.d.toString())
                ),
            ]
            setDOptions(
                uniqueD.map((option) => ({
                    value: option,
                    label: option,
                }))
            )
        }
        setLoading(false)
    }

    const handleDChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedD(selectedValue)

        setLoading(true)

        const dataWithSpecRhoGammaD = constraintsInformation.filter(
            (element) =>
                element.constraint_name === selectedRho.name &&
                element.contingency_name === selectedGamma.name &&
                element.d.toString() === selectedValue
        )

        if (dataWithSpecRhoGammaD.length === 0) {
            alert('There are no data for the d: ' + selectedValue)
            restartSelectedType()
        } else {
            setConstraintType(dataWithSpecRhoGammaD[0].type)
        }
        setLoading(false)
    }

    const handleFlowInfoButton = async (event) => {
        const currenStatus = selectedFlowDataChart
        if (currenStatus) {
            setSelectedFlowDataChart(false)
            setButtonFlowDataChartStyle({
                backgroundColor: 'white',
                color: '#969b9e',
                border: '2px solid #757575',
                'font-size': '12px',
            })
        } else {
            setSelectedFlowDataChart(true)
            setButtonFlowDataChartStyle({
                backgroundColor: '#1a313d',
                color: 'white',
                border: '2px solid #5b6c73',
                'font-size': '12px',
            })
        }
    }

    useEffect(() => {
        const setConstrInfo = () => {
            var textRequest = ''
            if (
                selectedRho.name === '' ||
                selectedGamma.name === '' ||
                selectedD === '' ||
                selectedFDScenario === '' ||
                runID === -1
            ) {
                setChartStrConstraintInfo('')
            } else {
                textRequest =
                    'run_id: ' +
                    runID +
                    '   rho: ' +
                    selectedRho.code +
                    '   gamma: ' +
                    selectedGamma.code +
                    '   d: ' +
                    selectedD +
                    '   scenario: ' +
                    selectedFDScenario
                setChartStrConstraintInfo(textRequest)
            }

            // For the graph of all the componets
            if (textRequest === '' || chartType === '') {
                setChartStrInfo('')
            } else {
                const textRequest2 = textRequest + '   chart: ' + chartType
                setChartStrInfo(textRequest2)
            }
        }
        setConstrInfo()
    }, [
        runID,
        selectedRho,
        selectedGamma,
        selectedD,
        selectedFDScenario,
        chartType,
    ])

    useEffect(() => {
        const drawFDChart = async () => {
            if (chartStrInfo === '') {
                return
            }
            if ((chartStrInfo !== '') & (chartStrInfo !== lastChartType)) {
                setFdChart(null)
                setFdTableData([])
                setLoading(true)
                try {
                    let res = await getFlowDecompChartData(
                        pipelineId,
                        runID,
                        parseInt(selectedFDScenario),
                        chartType,
                        parseInt(selectedRho.code),
                        parseInt(selectedGamma.code),
                        parseInt(selectedD)
                    )

                    if (res.error !== null) {
                        showAlert('Failed to retrieve information.')
                    } else {
                        setFdChart(res.data)
                        setFdTableData(castDataToRecords(res.data))
                    }
                } catch (error) {
                    showAlert('Failed to Show the chart.')
                    console.error('Error fetching options:', error)
                }
                setLastChartType(chartStrInfo)
                setLoading(false)
            }
        }
        drawFDChart()
    }, [showAlert, pipelineId, chartStrInfo])

    useEffect(() => {
        const drawFDChartFlowData = async () => {
            if (selectedFlowDataChart) {
                if (chartStrConstraintInfo === '') {
                    return
                }
                setFdChartFlowInfo(null)
                setLoading(true)
                try {
                    let res = await getFDConstraintInfoChart(
                        pipelineId,
                        runID,
                        parseInt(selectedFDScenario),
                        parseInt(selectedRho.code),
                        parseInt(selectedGamma.code),
                        parseInt(selectedD)
                    )

                    if (res.error !== null) {
                        showAlert('Failed to retrieve information.')
                    } else {
                        setFdChartFlowInfo(res.data)
                    }
                } catch (error) {
                    showAlert('Failed to Show the chart.')
                    console.error('Error fetching options:', error)
                }
                setLoading(false)
            } else {
                setFdChartFlowInfo(null)
            }
        }

        drawFDChartFlowData()
    }, [showAlert, pipelineId, chartStrConstraintInfo, selectedFlowDataChart])

    const handleMainSelection = (event, newV) => {
        setMainSelection(newV)
        setSecondarySelectionOptions([])
        setSecondarySelection('')
        setTertiarySelectionOptions([])
        setTertiarySelection('')
        setQuaternarySelectionOptions([])
        setQuaternarySelection('')

        if (newV === 'outages') {
            setSecondarySelectionOptions([])
            setChartType('flow')
        } else if (newV === 'gen') {
            const options = [
                { key: 'per_resource_type', value: 'Per Resource Type' },
                { key: 'per_zone', value: 'Per WZ' },
                {
                    key: 'per_resource_type_zone',
                    value: 'Per Resource Type and WZ',
                },
                { key: 'per_unit', value: 'Per Unit' },
            ]
            setSecondarySelectionOptions(options)
        } else if (newV === 'load') {
            const options = [
                { key: 'for_load', value: 'For Loads' },
                { key: 'for_ens', value: 'For ENS' },
                { key: 'for_all_components', value: 'For All Components' },
            ]
            setSecondarySelectionOptions(options)
        } else if (newV === 'dcTie') {
            setSecondarySelectionOptions([])
            setChartType('dc_tie_contrib')
        } else if (newV === 'pst') {
            setSecondarySelectionOptions([])
            setChartType('pst_contrib')
        } else if (newV === 'ens_ed') {
            setSecondarySelectionOptions([])
            setChartType('ens_ed_contrib')
        }
    }
    const handleSecondarySelection = (event, newV) => {
        setSecondarySelection(newV)
        if (mainSelection === 'gen' && newV === 'per_unit') {
            const options = [
                { key: 'var_criteria', value: 'Variance criteria' },
                { key: 'mean_criteria', value: 'Mean Criteria' },
            ]
            setTertiarySelectionOptions(options)
        } else if (mainSelection === 'load') {
            const options = [
                { key: 'per_zone', value: 'Per WZ' },
                { key: 'per_unit', value: 'Per Unit' },
            ]
            setTertiarySelectionOptions(options)
        } else if (mainSelection === 'gen' && newV === 'per_resource_type') {
            setChartType('gen_contrib_per_fuel')
            setTertiarySelectionOptions([])
        } else if (mainSelection === 'gen' && newV === 'per_zone') {
            setChartType('gen_contrib_per_zone')
            setTertiarySelectionOptions([])
        } else if (
            mainSelection === 'gen' &&
            newV === 'per_resource_type_zone'
        ) {
            setChartType('gen_contrib_per_fuel_and_zone')
            setTertiarySelectionOptions([])
        } else {
            setTertiarySelectionOptions([])
        }
    }

    const handleTertiarySelection = (event, newV) => {
        setTertiarySelection(newV)
        setQuaternarySelectionOptions([])
        if (newV === 'per_unit') {
            const options = [
                { key: 'var_criteria', value: 'Variance criteria' },
                { key: 'mean_criteria', value: 'Mean Criteria' },
            ]
            setQuaternarySelectionOptions(options)
        }
        if (
            mainSelection === 'gen' &&
            secondarySelection === 'per_unit' &&
            newV === 'var_criteria'
        ) {
            setChartType('')
            setChartType('gen_contrib_per_unit_var')
        } else if (
            mainSelection === 'gen' &&
            secondarySelection === 'per_unit' &&
            newV === 'mean_criteria'
        ) {
            setChartType('')
            setChartType('gen_contrib_per_unit_mean')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_load' &&
            newV === 'per_zone'
        ) {
            setChartType('')
            setChartType('load_contrib_per_zone_for_load')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_ens' &&
            newV === 'per_zone'
        ) {
            setChartType('')
            setChartType('load_contrib_per_zone_for_ens')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_all_components' &&
            newV === 'per_zone'
        ) {
            setChartType('')
            setChartType('load_contrib_per_zone')
        }
    }

    const handleQuaternarySelection = (event, newV) => {
        if (
            mainSelection === 'load' &&
            secondarySelection === 'for_load' &&
            tertiarySelection === 'per_unit' &&
            newV === 'var_criteria'
        ) {
            setChartType('')
            setChartType('load_contrib_per_unit_for_load_var')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_load' &&
            tertiarySelection === 'per_unit' &&
            newV === 'mean_criteria'
        ) {
            setChartType('')
            setChartType('load_contrib_per_unit_for_load_mean')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_ens' &&
            tertiarySelection === 'per_unit' &&
            newV === 'var_criteria'
        ) {
            setChartType('')
            setChartType('load_contrib_per_unit_for_ens_var')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_ens' &&
            tertiarySelection === 'per_unit' &&
            newV === 'mean_criteria'
        ) {
            setChartType('')
            setChartType('load_contrib_per_unit_for_ens_mean')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_all_components' &&
            tertiarySelection === 'per_unit' &&
            newV === 'var_criteria'
        ) {
            setChartType('')
            setChartType('load_contrib_per_unit_var')
        } else if (
            mainSelection === 'load' &&
            secondarySelection === 'for_all_components' &&
            tertiarySelection === 'per_unit' &&
            newV === 'mean_criteria'
        ) {
            setChartType('')
            setChartType('load_contrib_per_unit_mean')
        }
    }

    const drawSecondarySelection = () => {
        return secondarySelectionOptions.map((element) => (
            <ToggleButton
                disabled={loading}
                value={element.key}
                style={{
                    backgroundColor: '#300B16',
                    color: 'white',
                    'border-color': 'white',
                    'font-size': '10px',
                }}
            >
                {element.value}
            </ToggleButton>
        ))
    }
    const drawTertiarySelection = () => {
        return tertiarySelectionOptions.map((element) => (
            <ToggleButton
                disabled={loading}
                value={element.key}
                style={{
                    backgroundColor: '#160d1f',
                    color: 'white',
                    'border-color': 'white',
                    'font-size': '10px',
                }}
            >
                {element.value}
            </ToggleButton>
        ))
    }
    const drawQuaternarySelection = () => {
        return quaternarySelectionOptions.map((element) => (
            <ToggleButton
                disabled={loading}
                value={element.key}
                style={{
                    backgroundColor: '#3a2c3b',
                    color: 'white',
                    'border-color': 'white',
                    'font-size': '10px',
                }}
            >
                {element.value}
            </ToggleButton>
        ))
    }

    const handleFDScenarioChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedFDScenario(selectedValue)
    }

    const tooltip = {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var generalData = w.globals.initialSeries
            var seriesName = ''
            var seriesValue = ''
            var descriptionAvailable = 0
            var ingWith = 0
            var distfact = 0

            var colorPicked =
                w.globals.colors[seriesIndex % w.globals.colors.length]
            console.log(w.globals)
            var text =
                '<h4>TS: ' +
                moment
                    .utc(w.globals.seriesX[seriesIndex][dataPointIndex])
                    .format('YYYY-MM-DD HH:mm') +
                '</h4>'
            var styleForCurrentSerie = 'style="background-color: #acb3bf;"'

            text =
                text + '<table style="border: 2px solid' + colorPicked + ' ">'

            for (let i = 0; i < generalData.length; i++) {
                if ('description' in generalData[i]) {
                    descriptionAvailable = 1
                }
                if (i === 0) {
                    text = text + '<thead>'
                    text = text + '<tr>'
                    text = text + '<th>Category</th><th>Flow</th>'
                    if (descriptionAvailable) {
                        text =
                            text +
                            '<th>Inj/With BC</th><th>Tentative Distfact</th>'
                    }
                    text = text + '</tr>'
                    text = text + '</thead>'
                }

                text = text + '<tbody>'
                seriesName = generalData[i].name
                seriesValue = generalData[i].data[dataPointIndex]
                if (seriesValue !== undefined && seriesValue !== null) {
                    seriesValue.toFixed(4)
                }
                if (i === seriesIndex) {
                    text = text + '<tr ' + styleForCurrentSerie + '>'
                } else {
                    text = text + '<tr>'
                }
                text = text + '<td>' + seriesName + '</td>'
                text = text + '<td>' + seriesValue + ' MW</td>'
                if (descriptionAvailable) {
                    ingWith = generalData[i].description[dataPointIndex]
                    if (ingWith !== undefined && ingWith !== null) {
                        if (ingWith !== 0) {
                            distfact = (seriesValue / ingWith) * 100
                        } else {
                            distfact = 0
                        }
                        text = text + '<td>' + ingWith.toFixed(2) + ' MW</td>'
                        text = text + '<td>' + distfact.toFixed(2) + '%</td>'
                    } else {
                        text = text + '<td> NA </td>'
                        text = text + '<td> NA </td>'
                    }
                }
                text = text + '</tr>'
            }
            text = text + '</tbody></table>'
            return text
        },
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={12}>
                {loading && (
                    <div style={{ margin: '32px 0' }}>
                        <LinearProgress variant="indeterminate" />
                    </div>
                )}
            </Grid>

            <Grid item xs={2}>
                <Typography>Run Id</Typography>
                <Select
                    disabled={loading}
                    value={defaultToValue(runID, '', true)}
                    onChange={handleRunIdChange}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Run Id
                    </MenuItem>
                    {runIdOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
                <SearchableSelect
                    disabled={loading}
                    key={selectedRho.name}
                    label="Select Constraint"
                    value={selectedRho.name}
                    onChange={handleRhoChange}
                    options={rhoOptions}
                    keyPropFn={(option) => option.value}
                    valuePropFn={(option) => option.value}
                    showAll
                    removeSelectionText
                    formControlProps={{ fullWidth: true }}
                />
            </Grid>
            <Grid item xs={2}>
                <SearchableSelect
                    disabled={loading}
                    key={selectedGamma.name}
                    label="Select Contingency"
                    value={selectedGamma.name}
                    onChange={handleGammaChange}
                    options={gammaOptions}
                    keyPropFn={(option) => option.value}
                    valuePropFn={(option) => option.value}
                    showAll
                    removeSelectionText
                    formControlProps={{ fullWidth: true }}
                />
            </Grid>
            <Grid item xs={2}>
                <Typography>Direction</Typography>
                <Select
                    disabled={loading}
                    value={selectedD}
                    onChange={handleDChange}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Direction
                    </MenuItem>
                    {dOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={3}>
                <InputLabel>Information available</InputLabel>
                <TextField
                    disabled
                    variant="filled"
                    label={constraintType}
                    size="small"
                ></TextField>
            </Grid>

            <Grid item container xs={12} justifyContent="space-between">
                <Grid item xs={2}>
                    <Button
                        disabled={loading}
                        onClick={handleFlowInfoButton}
                        style={buttonFlowDataChartStyle}
                    >
                        Flow Data
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <ToggleButtonGroup
                        value={mainSelection}
                        exclusive
                        onChange={handleMainSelection}
                    >
                        <ToggleButton value="outages" disabled={loading}>
                            Outages
                        </ToggleButton>
                        <ToggleButton value="gen" disabled={loading}>
                            Gen
                        </ToggleButton>
                        <ToggleButton value="load" disabled={loading}>
                            Load
                        </ToggleButton>
                        <ToggleButton value="dcTie" disabled={loading}>
                            DC Tie
                        </ToggleButton>
                        <ToggleButton value="pst" disabled={loading}>
                            PST
                        </ToggleButton>
                        <ToggleButton value="ens_ed" disabled={loading}>
                            ENS and ED
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={3}>
                    <ToggleButtonGroup
                        value={secondarySelection}
                        exclusive
                        onChange={handleSecondarySelection}
                    >
                        {drawSecondarySelection()}
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={2}>
                    <ToggleButtonGroup
                        value={tertiarySelection}
                        exclusive
                        onChange={handleTertiarySelection}
                    >
                        {drawTertiarySelection()}
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={2}>
                    <ToggleButtonGroup
                        value={quaternarySelection}
                        exclusive
                        onChange={handleQuaternarySelection}
                    >
                        {drawQuaternarySelection()}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-start">
                <Grid item xs={2}>
                    <Typography>Scenario to draw</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Select
                        disabled={loading}
                        value={selectedFDScenario}
                        onChange={handleFDScenarioChange}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Scenario
                        </MenuItem>
                        {scenarioFDOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-chartStrInfo">
                            Chart Information
                        </InputLabel>
                        <OutlinedInput
                            disabled
                            variant="filled"
                            id="outlined-adornment-chartStrInfo"
                            value={chartStrInfo}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                {fdChartFlowInfo !== null &&
                    fdChartFlowInfo.data.series.length > 0 && (
                        <TaskChart visualization={fdChartFlowInfo} />
                    )}
            </Grid>
            <Grid item xs={12}>
                {fdChart !== null && fdChart.data.series.length > 0 && (
                    <TaskChart visualization={fdChart} tooltip={tooltip} />
                )}
            </Grid>
            <Grid item xs={12}>
                {fdTableData.length > 0 && (
                    <DataGrid
                        rows={fdTableData}
                        columns={generateColumns(fdTableData)}
                        autoHeight={true}
                        pageSize={5}
                        density={'compact'}
                        rowsPerPageOptions={[5]}
                        fullWidth={true}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default DAM3FlowDecompositionDriver
